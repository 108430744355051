<template>
  <section class="list">
    <div class="inner">
<!--      <h1>This is a list page</h1>-->
      <template v-if="activeBanks.length">
        <h2>{{ text.active[$store.getters.LANG] }}</h2>
        <div class="banks">
          <BankCard v-for="(bank, i) in activeBanks" :card="bank" :key="i"></BankCard>
        </div>
      </template>
      <h2>{{ text.list[$store.getters.LANG] }}</h2>
      <div class="needs">
        <div>
          <h3>{{ text.needed[$store.getters.LANG] }} <br><b class="perm">{{ text.constant[$store.getters.LANG] }}</b></h3>
          <span v-for="(item, i) in permanentsList[$store.getters.LANG]" :key="i">{{ item }}</span>
        </div>
        <div>
          <h3>{{ text.needed[$store.getters.LANG] }} <br><b class="impr">{{ text.urgent[$store.getters.LANG] }}</b></h3>
          <span v-for="(item, i) in importantsList[$store.getters.LANG]" :key="i">{{ item }}</span>
        </div>
      </div>
      <template v-if="closedBanks.length">
        <h2>{{ text.closed[$store.getters.LANG] }}</h2>
        <div class="banks closed">
          <BankCard v-for="(bank, i) in closedBanks" :card="bank" :closed="true" :key="i"></BankCard>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import api from "../api/api";
import BankCard from "../components/BankCard";

export default {
  name: "List",
  components: {BankCard},
  data() {
    return {
      activeBanks: [],
      closedBanks: [],
      importantsList: {ua: [], en: []},
      permanentsList: {ua: [], en: []},
      text: {
        active: {ua: 'Активнi збори', en: 'Active collections'},
        closed: {ua: 'Закритi збори', en: 'Closed collections'},
        list: {ua: 'Список необхiдних речей', en: 'List of necessary things'},
        needed: {ua: 'Необхiднi', en: 'Needed'},
        urgent: {ua: 'термiново', en: 'urgently'},
        constant: {ua: 'постiйно', en: 'constantly'}
      }
    }
  },
  created() {
    api.getData('get/l').then(r => {
      if (!r.data.error) {
        // console.log(r)
        const banks = r.data.list.banks || []
        for (let i = 0; i < banks.length; i++) {
          if (banks[i].closed) this.closedBanks.push(banks[i])
          else this.activeBanks.push(banks[i])
        }
        const needs = r.data.list.needs
        if (needs.important) {
          this.importantsList.ua = needs.important.ua.split(',')
          this.importantsList.en = needs.important.en.split(',')
        }
        if (needs.permanent) {
          this.permanentsList.ua = needs.permanent.ua.split(',')
          this.permanentsList.en = needs.permanent.en.split(',')
        }
      }
    })
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/list.scss"></style>
